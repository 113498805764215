<template>
    <div class="empty">
      <van-empty :image="require('@/assets/空.jpg')" description="程序猿在紧张开发中..."/>
    </div>
</template>

<script>
    export default {
        name: "Waiting"
    }
</script>

<style scoped>
.empty{
  background-color: #FFFFFF;
}
</style>
